<template>
  <router-link class="place-card grid-sm-4 grid-md-4 grid-lg-4"
               :to="`${$locale.base}${link}`">
    <div class="place-card__wrapper">
      <div class="place-card__image-holder">
        <transition name="fade"
                    mode="in-out">
          <img class="place-card__image"
               :src="image"
               alt/>
        </transition>
        <Favorite class="place-card__favorite"
                  :params="{id, entity, favorited}"/>
        <Row v-if="tags.length > 0"
             class="place-card__badges -m-h-5"
             wrap>
          <Badge v-for="badge in tags"
                 :key="`badge-place-card-${badge.id}`"
                 :params="{text: badge.title, colors: {
                       text: '#ffffff', background: badge.color}}"/>
        </Row>
      </div>
      <div class="place-card__meta">
        <div class="place-card__type-holder">
          <span class="place-card__type">{{$type(type)}}</span>
        </div>
        <div class="place-card__title-holder">
          <h5 class="place-card__title">{{title}}</h5>
        </div>
        <Row class="place-card__info -m-h-15">
          <Column class="place-card__info-column"
                  v-if="duration">
            <Icon class="place-card__info-icon"
                  xlink="duration"
                  viewport="0 0 20 20"/>
            <span class="place-card__info-value">{{params.duration}}</span>
          </Column>
          <Column class="place-card__info-column"
                  v-if="distance">
            <Icon class="place-card__info-icon"
                  xlink="route"
                  viewport="0 0 20 20"/>
            <span class="place-card__info-value">{{params.distance}}</span>
          </Column>
          <Column class="place-card__info-column">
            <Rating :params="{id,
                              component: 'Column',
                              align: 'start',
                              type: 'places',
                              rating,
                              currentUserRating}"/>
          </Column>
        </Row>
      </div>
    </div>
  </router-link>
</template>

<script>
import errorImage from '@/assets/images/svg/error.svg';

export default {
  name: 'PlaceCard',
  props: {
    params: {},
  },
  computed: {
    id() {
      return this?.params?.id;
    },
    entity() {
      return this?.params?.entity;
    },
    favorited() {
      return this?.params?.favorited;
    },
    rating() {
      return this?.params?.rating;
    },
    currentUserRating() {
      return this?.params?.currentUserRating;
    },
    distance() {
      return this?.params?.distance;
    },
    duration() {
      return this?.params?.duration;
    },
    title() {
      return this?.params?.title;
    },
    type() {
      return this?.params?.type;
    },
    tags() {
      if (this?.params?.tags?.length > 0) return this.params.tags;
      return [];
    },
    link() {
      return `/${this?.params?.entity}/${this?.params?.id}`;
    },
    image() {
      if (this?.params?.images?.length > 0) return this.params.images[0];
      return errorImage;
    },
  },
};
</script>
